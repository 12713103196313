import React, { Component } from 'react';
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import { Provider } from 'react-redux';
import buildStore from './redux/buildStore';
import {JssProvider} from 'react-jss';
import browser_locale from "browser-locale";
import Header from './components/Header';
import configurationPropType from "./proptypes/configurationPropType";

import PropTypes from 'prop-types';
import LoginWidget from "./components/LoginWidget";
import providerShape from "./proptypes/providerShape";

import "./styles/tailwind.css"

//import * as mocks from './mocks';
const sourceTheme = createTheme();
const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: 'inherit'
      }
    },
    MuiInput: {
      formControl: {
        //border: '1px solid #bbbbbb',
        //paddingRight: '6px',
        //paddingLeft: '6px',
        borderRadius: '4px',
      },
      focused: {
        //border: '1px solid #1976d2',
      },
      error: {
        borderColor: sourceTheme.palette.error.main,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '14px',
      },
      formControl: {
        //transform: 'none',
        //position: 'relative',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0px'
      }
    },
    MuiMenu: {
      paper: {
        backgroundColor: "#f5f5f5"
      }
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent"
        },
      }
    },
    MuiButton: {
      label: {
        whiteSpace: "nowrap"
      },
      root: {
        textTransform: "none",
        padding: "4px 10px",
        borderRadius: '2px',
        "&:hover": {
          backgroundColor: "transparent"
        },
      },
      contained: {
        boxShadow: 'none',
      },
      outlined: {
        padding: "3px 10px"
      }

    },
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  palette: {
    primary: {
      main: '#333F48',
    },
    background: {
      default: "#f5f5f5"
    }
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    let baseUrl = this.props.baseUrl;
    let splashImage = this.props.splashImage;
    let logoPath = "/assets/Sterling-RGB-2000x741.png";

    if (this.props.configuration && this.props.configuration.icon) {
      logoPath = this.props.configuration.icon;
    }
    let productName = 'Sterling';
    if (this.props.configuration && this.props.configuration.name) {
      productName = this.props.configuration.name;
    }
    let contactUsUrl = '/contact-us';
    if (this.props.configuration && this.props.configuration.contactUsUrl) {
      contactUsUrl = this.props.configuration.contactUsUrl;
    }
    let header = (
      <Header logoUrl={baseUrl + logoPath} />
    );

    const browserLocale = browser_locale().toLowerCase();
    let allowedLocales = ['en-us', 'fr-ca', 'es-mx'];
    if (this.props.allowedLocales) {
      allowedLocales = this.props.allowedLocales;
    }
    let locale = this.props.locale || browserLocale;

    if (!allowedLocales.includes(locale)) {
      locale = 'en-us';
    }

    let alertBannerParameters;

    try {
      alertBannerParameters = this.props.alertBanner ? JSON.parse(this.props.alertBanner) : {showAlert: false};
    } catch(error) {
      console.log("Parse error: alertBanner string to object parse failed. ", error); // eslint-disable-line
    }

    const storeDefaults = {
      loginForm: {
        alert: alertBannerParameters,
        serviceProviderName: this.props.serviceProviderName || '',
        allowClassicLoginExperience: this.props.allowClassicLoginExperience || false,
        classicLoginExperienceUrl: this.props.classicLoginExperienceUrl || '',
        userPoolId: this.props.userPoolId,
        clientId: this.props.client_id,
        productName: productName,
        contactUsUrl: contactUsUrl,
        autoIdpRedirectProviderData: this.props.autoIdpRedirectProviderData,
        enableAutoIdpRedirect: this.props.enableAutoIdpRedirect,
        loginClientId: this.props.loginClientId
      },
      intl: {
        allowedLocales: allowedLocales,
        defaultLocale: 'en-us', // This may not be used anywhere
        locale: locale
      }
    }
    if (this.props.logger) {
      storeDefaults.logger = true;
    }

    const store = buildStore(storeDefaults);

    return (
      <JssProvider>
        <MuiThemeProvider theme={theme} sheetsManager={new Map()}>
          <CssBaseline>
            <div className="App">
              <Provider store={store}>

                <LoginWidget header={header} defaultLocale={locale} baseUrl={baseUrl} splashImage={splashImage} showAlertBanner={alertBannerParameters.showAlert} />

              </Provider>
            </div>
          </CssBaseline>
        </MuiThemeProvider>
      </JssProvider>
    );
  }
}

App.propTypes = {
  allowClassicLoginExperience: PropTypes.bool,
  classicLoginExperienceUrl: PropTypes.string,
  serviceProviderName: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
  splashImage: PropTypes.string.isRequired,
  allowedLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
  configuration: configurationPropType,
  logger: PropTypes.bool,
  locale: PropTypes.string,
  userPoolId: PropTypes.string,
  client_id: PropTypes.string,
  alertBanner: PropTypes.string,
  autoIdpRedirectProviderData: providerShape,
  enableAutoIdpRedirect: PropTypes.bool,
  loginClientId: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired
}
export default App;
